import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText
} from 'reactstrap';

import GoogleBtn from './GoogleBtn';
import Blogs from './Blogs';
import CodeHighlighter from './CodeHighlighter';
import About from './About';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect} from "react-router-dom";
import BlogLinuxDesktop from './BlogLinuxDesktop';
import Goodies from './Goodies';
import Feedbacks from './Feedbacks';
import FeedbackForm from './FeedbackForm';
import {AuthContext} from './AuthContext'


class Home extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.highlightDefault = this.highlightDefault.bind(this);
        this.setAuthState = this.setAuthState.bind(this);
        // this.setStatus = this.setStatus.bind(this);

        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false, 
            first: true,
            second: false, 
            third: false,
            fourth: false,
            isLoggedIn: false,
            authState:{
                email: "",
                status: false,
                setStatus: this.setAuthState,
            }

        };

       
    }

    setAuthState = (status, email) => {
        // alert(change)
        this.setState(state => ({
            authState : {
                email: email,
                status : status, 
            setStatus: this.setAuthState
        },            
        }));
        console.log('setAuthState: status is ' + this.state.authState.status)
        console.log('setAuthState: email is ' + this.state.authState.email)
    }

    highlightDefault(selected){
        if(selected === 'first'){
            this.setState({
                first: true, 
                second: false, 
                third: false,
                fourth: false
            })
        }else if (selected === 'second'){
            this.setState({
                first: false, 
                second: true, 
                third: false,
                fourth: false
            })
        }else if (selected === 'third'){
            this.setState({
                first: false, 
                second: false, 
                third: true,
                fourth: false
            })
        }else {
            this.setState({
                first: false, 
                second: false, 
                third: false,
                fourth: true
            })
        }

        

    }

    closeDrawer(selected) {
        console.log('isOpen: ' + this.state.isOpen)
        if(this.state.isOpen){
            this.setState({
                isOpen: !this.state.isOpen,
                fadeIn: !this.state.fadeIn
            });

        }
       this.highlightDefault(selected)
        
    }

    toggle() {
        console.log('isOpen: ' + this.state.isOpen)
      
            this.setState({
                isOpen: !this.state.isOpen,
                fadeIn: !this.state.fadeIn
            });     
        
    }

    // componentDidMount() {
    //     fetch("http://localhost:3000/doctors")
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 console.log(result)
    //                 // alert(result)
    //                 this.setState({
    //                     isLoaded: true,
    //                     doctors: result
    //                 });
    //             },
    //             // Note: it's important to handle errors here
    //             // instead of a catch() block so that we don't swallow
    //             // exceptions from actual bugs in components.
    //             (error) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     error
    //                 });
    //             }
    //         )

            
    // }

    

    render() {

         
        return (
            

            <div>
                <Router>
                <div>

                <Navbar color="dark" dark expand="md">
                    {/* <NavbarBrand style={{color: 'white', textDecoration: 'none'}} activeStyle={{color: 'red', textDecoration: 'none'}} href="/">InfoEdge</NavbarBrand> */}
                    <NavbarBrand tag={Link} to="/" onClick={()=>this.highlightDefault('first')}>Architecture & Technology</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink onClick={()=>this.closeDrawer('first')} tag={Link} to="/blogs" active={this.state.first}>TECH TALKS / BLOGS</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={()=>this.closeDrawer('second')} tag={Link} to="/goodies" active={this.state.second}>SOFTWARE GOODIES</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={()=>this.closeDrawer('third')} tag={Link} to="/feedbacks" active={this.state.third}>FEEDBACK</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={()=>this.closeDrawer('fourth')} tag={Link} to="/about" active={this.state.fourth}>ABOUT</NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink onClick={()=>this.closeDrawer('fourth')} tag={Link} to="/feedbacks/form" active={this.state.fourth}>FEEDBACK FORm</NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                                <GoogleBtn/>
                            </NavItem> */}
                        </Nav>
                        <NavbarText><AuthContext.Provider value={this.state.authState}><GoogleBtn /></AuthContext.Provider></NavbarText>
                    </Collapse> 
                </Navbar>


               
                    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <Switch>
                        <Route path="/blogs/code-highlighter">
                            <CodeHighlighter />
                        </Route>
                        <Route path="/blogs/linux-desktop">
                            <BlogLinuxDesktop />
                        </Route>
                        <Route path="/blogs">
                            <Blogs />
                        </Route>
                        <Route path="/goodies">
                            <Goodies />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/feedbacks/form">
                        <AuthContext.Provider value={this.state.authState}><FeedbackForm /></AuthContext.Provider>
                        </Route>
                        <Route path="/feedbacks">
                        <AuthContext.Provider value={this.state.authState}><Feedbacks /></AuthContext.Provider>
                        </Route>                        
                        <Route  path="/">
                        <Redirect to="/blogs" />
                        </Route>

                    </Switch>
                    </div>
                </Router>

                

            </div>

        );
    }


}



// function Blogs() {
//     return <h2>Blogs</h2>;
// }

// function Goodies() {
//     return <h2>Goodies</h2>;
// }

// function About() {
//     return <h2>About</h2>;
// }


export default Home;