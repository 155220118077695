import React, { Component } from 'react';
import {
    Container} from 'reactstrap';





import { Card, CardTitle, CardText, CardBody, CardSubtitle } from 'reactstrap';

// import "./style.css";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import badge from "./images/badge.svg";
import webcomponentsBlue from "./images/webcomponents-published-blue.svg";

class CodeHighlighter extends Component {



    constructor(props) {
        super(props);


        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false

        };
    }

    render() {
        return (
            <Container className="themed-container" fluid={true}>
                <br></br>
                <Card>
                    {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                    <CardBody>
                        <CardTitle tag="h4">Polymer 3 WebComponent for Code Highlighting</CardTitle>
                        <br></br>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">20 OCT 2018</CardSubtitle>
                        <CardText font-size="bold">
                            {/* <p> */}
                            <br></br>
                    It started when I thought of writing a blog on my site. Since the blog was supposed to be around technology, I soon found my self
                    in the need of a web component that could perform the code highlighting of the sample code snippets, which were to be part of the blog.
                    <br></br><br></br>
                    An example would be, let's say I am writing a blog about Java 9 and want to show some code like below:
                    <br></br><br></br>

                            <pre>{`
                class Person {
                    private firstName = "Vikram";
                    private lastName = "Rawat";
                    public String getFirstName(){
                    return firstName;    
                    }
                    public String getLastName(){
                    return lastName;    
                    }
                } 
                
                `}</pre>

                            Notice that there is no highlighting in the code and I wanted it to look like below:
                    <br></br><br></br>

  
  
    <SyntaxHighlighter language="java" >
    {`class Person {
        private firstName = "Vikram";
        private lastName = "Rawat";
        public String getFirstName(){
          return firstName;    
        }
        public String getLastName(){
          return lastName;    
        }
      }`}
    </SyntaxHighlighter>

       {/* <SyntaxHighlighter language="java">
       'class Person {
        private firstName = "Vikram";
        private lastName = "Rawat";
        public String getFirstName(){
          return firstName;    
        }
        public String getLastName(){
          return lastName;    
        }
      }'
        

       
       </SyntaxHighlighter> */}

        {/* <code-highlighter lang="java">
        class Person {
        private firstName = "Vikram";
        private lastName = "Rawat";
        public String getFirstName(){
          return firstName;    
        }
        public String getLastName(){
          return lastName;    
        }
      } 

      </code-highlighter>     */}

                            <br></br>
                    Search and Analysis told me lot of ways this could be done. Below were some of the choices I had.
                    <br></br>
                    1. <a href="https://github.com/google/code-prettify">Google Code prettify</a><br></br>
                    2. <a href="https://github.com/PolymerElements/prism-element">Prism-Element Web-Component</a><br></br>
                    3. <a href="https://github.com/kenchris/code-snippet">Code-Snippet Web-Component</a><br></br>
                            <br></br>
                    Unfortunately, none of these worked with Polymer 3 and after investing some time, I started reaching out to the community. <br></br><br></br>
                    1. <a href="https://stackoverflow.com/questions/52681549/google-code-prettify-code-highlighting-not-working-for-polymer-3">Stack Overflow</a><br></br>
                            <br></br>
                    2. I made similare queries in Slack and other channels as well. <br></br><br></br>
                    With all the feedback, I realized that most of the existing stuff was not compatible with Polymer 3 that I was using and therefore I decided to
                    take a step back and put my thinking cap on again. <br></br>
                    One option was to drop Polymer and use some other technology like ReactJs. 
                     <br></br><br></br>
                    Finally, I decided to try and develop my own custom web-component to solve my problem. This was challenging for me as I rarely do Front End Development. <br></br>
                    But I have always been passionate about UI as that is the layer that directly interfaces with the end-user; though AI is changing this at a fast pace. <br></br>
                    I started on the task and was able to get something working, thanks to the long weekend. <br></br><br></br>

                    Basically, created a new HTML tag, <code><b>{`<code-highlighter></code-highlighter>`}</b></code>, which will automatically highlight any code that is added as the content of this tag.

                    Once I was satisfied with the web-component, I published it to the coumminty. <br></br><br></br>

                            <a href="https://badge.fury.io/js/polymer-code-highlighter"><img src={badge} alt="npm version" height="18" /></a>
                            <a href="https://www.webcomponents.org/element/polymer-code-highlighter"><img src={webcomponentsBlue} alt="npm version" height="18" /></a>

                            <br></br><br></br>Hope it will be useful!!

                {/* </p>   */}
                        </CardText>
                    </CardBody>
                </Card>

            </Container>



        );
    }
}

export default CodeHighlighter;