import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Button} from 'reactstrap';


import { Card, CardTitle, CardText, CardDeck, CardBody, CardSubtitle, CardLink, CardImg } from 'reactstrap';

import YoutubeEmbed from "./YoutubeEmbed";
import "./style.css";
import badge from "./images/badge.svg";
import webcomponentsBlue from "./images/webcomponents-published-blue.svg";
// import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import FaceRecognition from "./images/FaceRecognition.png"
import Serverless from "./images/Embrace Serverless.png"

class Blogs extends Component {

    constructor(props) {
        super(props);

        
        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false

        };
    }

    render() {
        



        return (

            
            <Container className="themed-container" fluid={true}>
 <br></br>
{/* <Row>
<Col xs="12" sm="6"> */}
           <CardDeck>
            <Card  color="secondary" outline>
              
              <CardBody>
                <CardTitle tag="h5">Face Recognition (Fun With Machine Learning) </CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">24 OCT 2021</CardSubtitle>
                <CardText>
                <br></br><b>Background:</b> 
                <br></br>The field of Computer Vision has seen phenomenal growth in last 8 years or so. With AlexNet introducing new possibilities, especially with ReLU, and then with VGG, GoogleNet, ResNet etc. following suit and advancing the capabilities further, the CNNs became much more mainstream for computer vision tasks.
                
                </CardText>
                {/* <LiteYoutubeEmbed id="wTXefjN_Yu8" /> */}
                {/* <YoutubeEmbed embedId="BrAvvhcS3JM"  /> */}
                {/* <CardLink href="https://www.linkedin.com/pulse/reactive-architecture-get-more-out-your-hardware-vikram-rawat/" style={{ color: 'black' }}>Read the Blog on LinkedIn</CardLink> */}
                <br></br>
                <a href="https://www.linkedin.com/pulse/face-recognition-vikram-rawat/"><Button>Read the Blog on LinkedIn</Button></a>
                <CardImg top width="100%" src={FaceRecognition} alt="Card image cap" />
              </CardBody>
            </Card>

            {/* </Col>

            <Col xs="12" sm="6"> */}

            <Card  color="secondary" outline>
              {/* <CardImg top width="100%" src={ReactiveSpring} alt="Card image cap" height="256px" width="186px"/> */}
              <CardBody>
                <CardTitle tag="h5">Embrace Serverless</CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">02 OCT 2021</CardSubtitle>
                <CardText><b>Background</b>
<br></br>
Serverless computing has been in existence since 2014, first launched by Amazon Web Services (AWS) cloud. While Cloud was already offering ‘pay as you go’ pricing models, modeling the utility bills example, where you pay for services you consumed, there was still potential to provide more cost savings to customers. Working backwards from this goal of providing more cost benefits to customers, AWS invented the Serverless computing paradigm. 
<br></br><br></br>
<b>Cost Benefits</b>
<br></br>
Serverless computing further optimizes the 'pay as you go' pricing models by excluding the cost when the Cloud Service was not processing any requests. In other words, only when...
</CardText>
             
                         
                         <br></br>
                         <a href="https://www.linkedin.com/pulse/embrace-serverless-vikram-rawat"><Button>Read the full Blog on LinkedIn</Button></a>
                         <CardImg top width="100%" src={Serverless} alt="Card image cap" />
              
              </CardBody>
            </Card>
           {/* </Col>
            </Row> */}
            </CardDeck>
          
          
          
          
            <br></br>
            <CardDeck>
            <br></br>
           
            <Card  color="secondary" outline>
              {/* <CardImg top width="100%" src={ReactiveSpring} alt="Card image cap" height="256px" width="186px"/> */}
              <CardBody>
                <CardTitle tag="h5">Architecture Trade-offs: Servlet vs Reactive</CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">13 OCT 2019</CardSubtitle>
                <CardText>Deep Dive and trade-offs of 2 architecture styles (Java ecosystem) across Performance, Scalability and Resiliency</CardText>
                {/* <Button>Button</Button> */}
              
                         
                          {/* <CardLink href="https://youtu.be/BVq2cZPV0M8">Watch the Talk on Youtube </CardLink> */}
                          <YoutubeEmbed embedId="BVq2cZPV0M8" />
                          {/* <LiteYoutubeEmbed id="BVq2cZPV0M8"/>  */}
                          {/* <CardLink href="https://www.slideshare.net/vikram7981/architecture-patterns-servlet-vs-reactive?ref=">View / Download slide deck from slideshare</CardLink> */}
                         <br></br>
                         <a href="https://www.slideshare.net/vikram7981/architecture-patterns-servlet-vs-reactive?ref="><Button>View / Download slide deck from slideshare</Button></a> 
                           
              
              </CardBody>
            </Card>
            <Card  color="secondary" outline>
              {/* <CardImg top width="100%" src="/assets/ReactiveSpring.png" alt="Card image cap" /> */}
              <CardBody>
                <CardTitle tag="h5">Reactive Architecture: Get More out of your Hardware</CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">25 NOV 2018</CardSubtitle>
                <CardText>Comparing performance of Spring MVC vs Spring Reactive via a Load Test. </CardText>
                {/* <LiteYoutubeEmbed id="wTXefjN_Yu8" /> */}
                <YoutubeEmbed embedId="wTXefjN_Yu8" />
                {/* <CardLink href="https://www.linkedin.com/pulse/reactive-architecture-get-more-out-your-hardware-vikram-rawat/" style={{ color: 'black' }}>Read the Blog on LinkedIn</CardLink> */}
                <br></br>
                <a href="https://www.linkedin.com/pulse/reactive-architecture-get-more-out-your-hardware-vikram-rawat/"><Button>Read the Blog on LinkedIn</Button></a>
              </CardBody>
            </Card>
           
            </CardDeck>
            
            <Row>
            
                <Col xs="12" sm="6">
                <br></br>
                    <Card  color="secondary" outline>
                    {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                    <CardBody>
                        <CardTitle tag="h5">Polymer 3 WebComponent for Code Highlighting</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">20 OCT 2018</CardSubtitle>
                        <CardText>Useful when Source Code (e.g. Java Code) needs to be rendered on a page, for instance, when you want to add code snippets to a blog or a tutorial.</CardText>
                        <a href="/blogs/code-highlighter"><Button>Read more...</Button></a>
                        <br></br><br></br>
                        <CardLink href="https://www.webcomponents.org/element/polymer-code-highlighter/demo/demo/index.html">See a demo on WebComponent official site</CardLink>
                        <br></br><br></br>
                        <CardText>Average 20 downloads per week ~ Total 1600+ downloads</CardText>
                        
                        <br></br>
                        <CardLink href="https://badge.fury.io/js/polymer-code-highlighter"><img src={badge} alt="npm version" height="18"/></CardLink>
                        <CardLink href="https://www.webcomponents.org/element/polymer-code-highlighter"><img src={webcomponentsBlue} alt="npm version" height="18"/></CardLink>

                    </CardBody>
                    </Card>
                    <br></br>

                    <Card  color="secondary" outline>
                        {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                        <CardBody>
                            <CardTitle tag="h5">It's Time: Linux Desktop replacing Windows for Java Development</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">05 JAN 2017</CardSubtitle>
                            <CardText>If you are a Java Developer using a Windows laptop then it's time for you to switch from Windows to Linux. 
                                        This is because:<br></br>
                                        1. All professional modern development uses Docker containers, which is primarily a Linux technology.  </CardText>
                            <a href="/blogs/linux-desktop"><Button>Read more...</Button></a>
                        
                            
                        </CardBody>
                   </Card>
                  
                </Col>
                
                <Col xs="12" sm="6">
                <br></br>
                <Card  color="secondary" outline>
                            {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                            <CardBody>
                                <CardTitle tag="h5">IOT: SMS Shoulder Tap</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">21 MAR 2017</CardSubtitle>
                                <CardText>SMS Shoulder Tap is the concept wherein the IOT Platform sends a SMS to the Device and thereby wakes up the Device i.e., the devices comes out of low power modes and comes Online and then exchange data with the Platform and can then go back to sleep again.</CardText>
                                <YoutubeEmbed embedId="Qn3M8te1hG8" />
                                {/* <LiteYoutubeEmbed id="Qn3M8te1hG8" /> */}
                                <br></br>

                                <a href="https://github.com/aerisiot/sms-shoulder-tap"><Button>Check out the detailed guide on Github</Button></a>
                            </CardBody>
                        </Card>
                        <br></br>
                </Col>
                
            </Row>
            

         
    </Container>

        );
    }
}

export default Blogs;