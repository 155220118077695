import React, { Component } from 'react';
import {
    Container} from 'reactstrap';





import { Card, CardTitle, CardText, CardBody, CardSubtitle } from 'reactstrap';

// import "./style.css";

class BlogLinuxDesktop extends Component {



    constructor(props) {
        super(props);

        
        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false

        };
    }

    render() {
        return (
            <Container className="themed-container" fluid={true}>
            <br></br>
            <Card>
            {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
            <CardBody>
                <CardTitle tag="h4">It's Time: Linux Desktop replacing Windows for Java Development</CardTitle>
                <br></br>
                <CardSubtitle tag="h6" className="mb-2 text-muted">05 JAN 2017</CardSubtitle>
                <CardText font-size="bold">
                <br></br>
                <p>
                If you are a Java Developer using a Windows laptop then it's time for you to switch from Windows to Linux, unless you can afford costly Macs. This is because:<br></br> 
                </p>  
                <div>
                1. All professional modern development uses Docker containers, which is primarily a Linux technology.    
                <br></br><br></br>

                Yes, there are options available to run Docker on a Windows PC. But these options are painful and costly. I say "costly" because these work by basically installing a VirtualBox on Windows and will then load LinuxVM on VirtualBox. So, it's a huge penalty/cost in terms of Hardware resources. You need 12GB+ of RAM to make this work smoothly.

                <br></br>And it's "painful" because VirtualBox keeps breaking with any update to Windows OS, especially the case with corporate laptops where the auto-updates (security patches etc.) are pushed to all PCs on office LAN.

               </div>
              <br></br>
              <div>
               2. Linux Desktops have matured and have enough software support to provide a complete eco-system of applications for daily needs.
               <br></br><br></br>

               I installed Ubuntu distribution last week and the switch over from windows has been smooth and easy. It provides an option to dual boot with windows so that the transition can be done gradually.
               </div>

              <br></br>
              <div>
               3. With Linux and Docker, developers can achieve more with the same hardware. For instance, one can setup an entire Integration environment, comprising of different kinds of Applications, on his/her laptop; something that wasn't possible in the pre-docker era.
               </div>


              <br></br>
              <p>In summary, Dockers are now main-stream and Linux and Docker go hand-in-hand. Linux is definitely a better choice than Windows to exploit the full power of the developer machine and achieve high developer productivity.</p>
           </CardText>
            </CardBody>
            </Card>
           
          </Container>
        
            

        );
    }
}

export default BlogLinuxDesktop;