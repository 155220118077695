import React, { Component } from 'react';
import {
    Container,
    Row,
    Col} from 'reactstrap';


import { Card, CardTitle, CardText, CardBody } from 'reactstrap';

// import "./style.css";
import { MdFileDownload } from 'react-icons/md';

import inMemoryJWTManager from './inMemoryJWTManager';
class Goodies extends Component {



    constructor(props) {
        super(props);

        
        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false

        };

        
    }

    async componentDidMount () {
        console.log("--- component mounted ---")
        console.log("inMemoryJWTManager.getToken(): "+ inMemoryJWTManager.getToken())
    }

    render() {
        
        return (
            <Container className="themed-container" fluid={true}>
            
            <Row>
            
                <Col xs="12" sm="6">
                <br></br>
                    <Card body inverse style={{ backgroundColor: '#a2b9bc', borderColor: '#a2b9bc' }}>
                    {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                    <CardBody>
                        <CardTitle tag="h5">ScreenSaver using Java</CardTitle>
                        {/* <CardSubtitle tag="h6" className="mb-2 text-muted">20 OCT 2018</CardSubtitle> */}
                        <CardText tag="div">Prevents Computer from getting into a suspend/lock/sleep state. e.g.:
                                <li>During presentations / meetings / conferences</li> 
                                <li>During Long running tasks e.g. Data Migrations</li>
                                <hr  style={{
                                                color: 'white',
                                                backgroundColor: 'white',
                                                height: .1,
                                                borderColor : 'white'
                                            }}/>


Instructions:<br></br>
         1. <a href="https://www.dropbox.com/s/ley89xogdekaszh/screensaver.jar?dl=1" style={{ color: 'white' }}><MdFileDownload /> Download the jar file ("screensaver.jar").</a> <br></br>
         2. In a terminal (Linux / Mac) or cmd (windows OS), run following command:<br></br>
         java -jar screensaver.jar             
                        </CardText>
                        
                    </CardBody>
                    </Card>
                    <br></br>                   
                </Col>
                
                <Col xs="12" sm="6">
                <br></br>
                <Card body inverse style={{ backgroundColor: '#2db4aa', borderColor: '#2db4aa' }}>
                            {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                            <CardBody>
                                <CardTitle tag="h5">Docker image for Kafka+Zookeeper</CardTitle>
                                {/* <CardSubtitle tag="h6" className="mb-2 text-muted">21 MAR 2017</CardSubtitle> */}
                                <CardText tag="div">
                                <br></br>
                                    Quick bootstrap to start Development on Kafka. It provides:
                                        <li>Kafka and Zookeper running within the same Docker container</li> 
                                        <li>Developer can focus on writing application code and not worry about setting up Infrastructure</li> 

                                        <hr  style={{
                                                color: 'white',
                                                backgroundColor: 'white',
                                                height: .1,
                                                borderColor : 'white'
                                            }}/>


                                        Instructions:<br></br>
                                        1. Command to start Kafka:<br></br>
                                       docker run -it --name USER_DEFINED_NAME -p 2181:2181 -p 9092:9092 vrawat/ibus <br></br>
                                        
                                        e.g. docker run -it --name myKafka -p 2181:2181 -p 9092:9092 vrawat/ibus <br></br><br></br>
                                    
                                      

         2. <a href="https://hub.docker.com/r/vrawat/ibus/" style={{ color: 'white' }}> For more details, check out the documentation on Docker Hub Repo</a> <br></br>
                                </CardText>

                              
                            </CardBody>
                        </Card>
                </Col>
                
            </Row>          

         
    </Container>

        );
    }
}

export default Goodies;