import React, { Component, useContext } from 'react';
import {
    Container,
    Row,
    Col,
    Alert,
    CardFooter
} from 'reactstrap';


// import { Card, CardTitle, CardText, CardBody } from 'reactstrap';

// import "./style.css";
import LinkedInLogo from "./images/LinkedInLogo.png";
import StackOverflow from "./images/StackOverflow.png"
import Github from "./images/Github.png"
// import {UserContext} from "./UserContext"
import { AuthContext } from './AuthContext';
import { Card, CardTitle, CardText, CardDeck, CardBody, CardSubtitle, CardLink, CardColumns, CardImg, Button, Spinner } from 'reactstrap';

import GoogleBtn from './GoogleBtn';
import {

    Link
} from "react-router-dom";
import inMemoryJWTManager from './inMemoryJWTManager';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

class Feedbacks extends Component {

    // static contextType = UserContext;
    // needed to use location
    static propTypes = {
        location: PropTypes.object.isRequired,
    
   }


    constructor(props) {
        super(props);


        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false,
            feedbacks: [],
            outline: ["primary", "secondary", "success", "info", "warning", "danger"],
            changed: false

        };

        let changed = this.props.location.state;        
        console.log("***** "+ changed);

        if(changed)
        this.setState({changed: true});


    }

    componentDidMount() {

        // console.log("--- component mounted ---")
        // console.log("inMemoryJWTManager.getToken(): "+ inMemoryJWTManager.getToken())
        this.getAllFeedback();

    }

    componentDidUpdate() {

        console.log("--- componentDidUpdate ---")
        // console.log("inMemoryJWTManager.getToken(): "+ inMemoryJWTManager.getToken())
        if(this.state.changed){
            this.getAllFeedback();
            this.setState({changed: false});

        }
        

    }

    getAllFeedback(){

        console.log ("**********************")

        //call a POST API to save feedback on backend
        let phToken = inMemoryJWTManager.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': phToken

            }            
        };

        fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/local/feedbacks", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    // alert(result)

                    //sort the result 
                    this.setState({
                        isLoaded: true,
                        feedbacks: result.Items.reverse()
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }

    deleteFeedback(id){
        let phToken = inMemoryJWTManager.getToken();           
            console.log("--- id: " + id)


        //call a DELETE API to update feedback on backend
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': phToken
                
            },
                   
            // body: JSON.stringify({
            //   "title": this.state.title,
            //   "feedback": this.state.feedback
            // } )
        };
    
          
          fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/feedbacks/"+id, requestOptions)
          .then(res => res.json())
          .then(
              (result) => {
                  console.log("Feedbacks result: ");
                  console.log(result);                

                  //resetting state
                  let filteredFeedback = this.state.feedbacks.filter(feedback => feedback.sortKey !== id);
                  this.setState({feedbacks: filteredFeedback});
                //   console.log("state: " + this.state.feedbacks)
                
              },              
              (error) => {
                console.log("Feedbacks result with error: " + error);
              }
          )

    }

    render() {

        // this.getAllFeedback();

        console.log('Feedback::this.context.status: ' + this.context.status)


        // alert('Feedback:this.context.status: '+ this.context.status)
        const status = this.context.status
        const email = this.context.email;

        return (
            <Container className="themed-container" fluid={true}>
                <br></br>

                {
                    status &&
                    <Alert color="info">
                        You can leave your <Link to="feedbacks/form">feedback here!!</Link>
                    </Alert>
                }

                {
                    (!status) && <Alert color="info">
                        Please login, using the button available in the menu, so that you can leave feedback !!
                    </Alert>
                }

                {
                    (this.state.feedbacks.length == 0) &&
                    <div align="center">
                        <Spinner type="grow" color="primary" />
                        <Spinner type="grow" color="secondary" />
                        <Spinner type="grow" color="success" />
                        <Spinner type="grow" color="danger" />
                        <Spinner type="grow" color="warning" />
                        <Spinner type="grow" color="info" />
                        {/* <Spinner type="grow" color="light" /> */}
                        <Spinner type="grow" color="dark" />
                    </div>

                }

                <CardColumns>
                    {

                        this.state.feedbacks.map((feedback, color) => {
                            {/* console.log(color) */}
                            color = (color) % 6;
                            {/* console.log('ans: ' + color) */}
                            {/* {this.state.outline[color]} */ }
                            return (

                                <Card outline color={this.state.outline[color]}>
                                    {/* <Card body outline color={feedback.color}>  */}
                                    {/* <CardImg top width="10%" src={feedback.picURL} alt="Card image cap" /> */}

                                    <CardBody>

                                        <CardTitle tag="h5">{feedback.title}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">{feedback.date}</CardSubtitle>
                                        <CardText>{feedback.feedback}</CardText>


                                    </CardBody>

                                    <CardFooter>

                                        <img src={feedback.picUrl} alt={feedback.fullName} width="10%" className="rounded-circle" title={feedback.fullName} /> &nbsp; {feedback.fullName} &nbsp; &nbsp;
                                        {
                                            //show edit only if the card belongs to user 

                                            feedback.email == email && <Link to={{
                                                pathname: "feedbacks/form",
                                                state: {
                                                    title: feedback.title,
                                                    feedback: feedback.feedback,
                                                    mode: "edit",
                                                    id: feedback.sortKey
                                                }
                                            }} >


                                                <Button outline color={this.state.outline[color]}>Edit</Button>
                                            </Link>

                                        }

                                        &nbsp;&nbsp;

                                        {
                                            //show delete only if the card belongs to user

                                            feedback.email == email &&


                                                <Button outline color={this.state.outline[color]} onClick={()=>this.deleteFeedback(feedback.sortKey)}>Delete</Button>
                                            
                                        }


                                    </CardFooter>


                                </Card>

                            )


                        })
                    }
                </CardColumns>



            </Container>
        );

    }


    // }
}
Feedbacks.contextType = AuthContext;
export default withRouter(Feedbacks);