import React, { Component } from 'react';
import {
    Container,
    Row,
    Col} from 'reactstrap';


import { Card, CardTitle, CardText, CardBody } from 'reactstrap';

// import "./style.css";
import LinkedInLogo from "./images/LinkedInLogo.png";
import StackOverflow from "./images/StackOverflow.png"
import Github from "./images/Github.png"
// import Vikram from "./images/vikram.png"
class About extends Component {



    constructor(props) {
        super(props);

        
        this.state = {
            isOpen: false,
            error: null,
            isLoaded: false,
            doctors: [],
            fadeIn: false

        };

        
    }

    render() {
        
        return (
            <Container className="themed-container" fluid={true}>
            
            <Row>
            
                <Col xs="12" sm="9">
                <br></br>
                    <Card >
                    {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                    <CardBody>
                        <CardTitle tag="h5">Who am I?</CardTitle>
                        {/* <CardSubtitle tag="h6" className="mb-2 text-muted">20 OCT 2018</CardSubtitle> */}
                                              
                        
                        <CardText><br></br>I am <b>Vikram Rawat</b>, a passionate technologist and hands-on Architect. I work as "Head of Prototyping" in <a href="https://aws.amazon.com/">AWS (India)</a>, 
                        a subsidiary of Amazon providing on-demand cloud computing platforms and APIs to individuals, companies, and governments, on a metered pay-as-you-go
                        basis. In this role, I built the Prototyping Program for India and act as a hands-on Tech Leader, who works closely with 
                        customers to solve complex and innovative use cases by building rapid prototypes and accelerating business outcomes. 
                        Multi-faceted role (<b>Executive:</b> driving customer discussion, <b>Architect:</b> providing solution blueprint, <b>Leader:</b> guiding and 
                        mentoring the team, <b>Engineer:</b> building and deploying a few)
                             
                        </CardText>
                        
                    </CardBody>                   
                    </Card>
                                   
                </Col>
                <Col xs="12" sm="3">
                <br></br>
                    <Card >
                    {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                    <CardBody>
                        <CardTitle tag="h5">Social Profiles</CardTitle>
                        {/* <CardSubtitle tag="h6" className="mb-2 text-muted">20 OCT 2018</CardSubtitle> */}
                        <CardText tag="div"><br></br>
                        
                        <p>
                        <img alt="" src={LinkedInLogo} height="25px" width="25px"/><a href="https://www.linkedin.com/in/vikramrawat/" className="viewProfile">  View LinkedIn Profile</a>
                        </p>

                        <p>
                        <img alt="" src={StackOverflow} height="25px" width="25px"/><a href="https://stackoverflow.com/users/3918250/vikram-rawat" className="viewProfile"> View Stack Overflow Profile</a>
                        </p>

                        <p>
                            <img alt="" src={Github} height="25px" width="25px"/><a href="https://github.com/vrawat79" className="viewProfile"> View Git Hub Profile</a> 
                        </p>
       
                             
                        </CardText>
                        
                    </CardBody>
                    </Card>
                                   
                </Col>
                </Row>
                <Row>
                <Col xs="12" sm="9">
                <br></br>
                <Card >
                            {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                            <CardBody>
                                <CardTitle tag="h5">Why this Site?</CardTitle>
                                {/* <CardSubtitle tag="h6" className="mb-2 text-muted">21 MAR 2017</CardSubtitle> */}
                                <CardText tag="div">
                                <br></br>I started vikram-rawat.com to consolidate artifacts such as blogs, re-usable code etc. that I create in my 
                                own free time, e.g. weekends and early mornings. Moreover, as I grow in the industry, I want to contribute back to the
                                developer community and this site can be the platform to enable those activities. Lastly, this allows me to keep myself
                                hands-on, as I develop and maintain this site end to end. Whether it is frontend / UI or API or Security (OAUTH), it allows 
                                full stack development experience and keeps me honest. 
                                    
                                </CardText>

                              
                            </CardBody>
                        </Card>
                </Col>

                <Col xs="12" sm="3">
                <br></br>
                <Card >
                            {/* <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
                            <CardBody>
                                <CardTitle tag="h5">Home</CardTitle>
                                {/* <CardSubtitle tag="h6" className="mb-2 text-muted">21 MAR 2017</CardSubtitle> */}
                                <CardText tag="div">
                                <br></br>I live in Delhi with my wife Manju and two lovely daughters, Niyati and Vanshika. We stay blessed under the 
                                calming presence of my parents, who live with me and my two younger brothers; all staying together in the same building. 
                                I enjoy playing Cricket, Badminton, Chess etc. with the family whenever I can :)                                    
                                </CardText>

                              
                            </CardBody>
                        </Card>
                </Col>
                
            </Row>          

         
    </Container>

        );
    }
}

export default About;