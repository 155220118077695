import React, { Component, useContext } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import Home from './Home';
import inMemoryJWTManager from './inMemoryJWTManager';
// import UserContext from './UserContext';
// import { UserContext } from './Home';

// import {userContext} from './UserContext';

// import {AuthContext} from './Home';
import {AuthContext} from './AuthContext'
const CLIENT_ID = '435468805013-qp89pdv6fi35vsi7l258g1eh3iq6h7c3.apps.googleusercontent.com';


class GoogleBtn extends Component {


   constructor(props) {
    super(props);

    // this.inputFile=React.createRef();

    this.state = {
      isLogined: false,
      tokenId: '',
      imageUrl:'',     
      errorcounter: 0,
      email:''
    };  

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
    
  }

  login (response) {
    console.log(response);
    if(response.tokenId){
      this.setState(state => ({
        isLogined: true,
        tokenId: response.tokenId,
        imageUrl: response.profileObj.imageUrl
      }));

      //Id token provides authentication information, i.e. identity of the user
      // Access token provides authorization, i.e. access to google resources
      console.log("-- Google's Id token that has identity ----")
      console.log(this.state.tokenId)

      //pass id token to backend for verification that it is a valid token, i.e. signed by google and not expired
      //backend will return an Access token for authorization to PH resources
      
      //make a POST call, passing User json in body
      //it will create the user node, as needed, and return AT

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'grant_type':'jwt_bearer' },
        //for sending and receiving cookies.. sometime 'include' works as an alternate value
        credentials: 'include', 
        body: JSON.stringify({
          "gTokenId": this.state.tokenId
        } )
    };

      //pass Google Id Token to Backend to receive corresponding AT
      fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/tokens", requestOptions)
      .then(res => res.json())
      .then(
          (result) => {
              console.log("Platform Token: ");
              console.log(result);
              // alert(result.body)
              inMemoryJWTManager.setToken(result.body.token);
              console.log('token saved:'+ inMemoryJWTManager.getToken());
              this.setState({
                  isLoaded: true,
                  email: result.body.email,
                  imageUrl: result.body.picURL
                  // doctors: result
              });

              console.log('GoogleBtn: email: ' + this.state.email)
    // console.log(this.state.isLogined)
    // console.log('context::' + this.context);

    // this.context.setState({
    //   loggedIn: this.state.isLogined

    // });
            console.log('in login context::' + this.context.status);
            this.context.setStatus(this.state.isLogined, this.state.email)    
            console.log('in login context::' + this.context.status);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
              this.setState({
                  isLoaded: true,
                  error
              });
          }
      )


    }

    
  }

  logout (response) {
    console.log('logout::email:' + this.state.email)
    console.log('logout::token:' + inMemoryJWTManager.getToken())

    //also clean the tokens on backend
    let phToken = inMemoryJWTManager.getToken();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',  'Authorization': phToken},
      //for sending and receiving cookies.. sometime 'include' works as an alternate value
      credentials: 'include'
      // body: JSON.stringify({
      //   "phToken": inMemoryJWTManager.getToken()
      // } )
  };

    //pass access Token to Backend to do logout cleanup...
    fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/logout", requestOptions)
    .then(res => res.json())
    .then(
        (result) => {
            console.log("Logout Response: " + result);         
            //response should remove the cookie... setting it to expire immediately   
        });
    inMemoryJWTManager.eraseToken();
    console.log('token erased');
      
    this.setState(state => ({
      isLogined: false,
      tokenId: '',
      imageUrl: '',
      email: ''
    }));

    console.log('in logout context::' + this.context.status);
    this.context.setStatus(this.state.isLogined, this.state.email)
    console.log('in logout context::' + this.context.status);

  }

  handleLoginFailure (response) {
    // alert('Failed to log in')
    console.log('Failed to log in')
  }

  handleLogoutFailure (response) {
    console.log('Failed to log out')
  }
 


  render() {
    
    
    console.log('in render context::' + this.context.status);

    // alert(this.context.value);

    return (
    <div>
      { this.state.isLogined ?<>
        <img src={this.state.imageUrl} alt="" width="30px" className="rounded-circle"/>
        <GoogleLogout
          clientId={ CLIENT_ID }
          buttonText='Logout'
          onLogoutSuccess={ this.logout }
          onFailure={ this.handleLogoutFailure }
        >
        </GoogleLogout></>: <GoogleLogin
          clientId={ CLIENT_ID }
          buttonText='Login'
          onSuccess={ this.login }
          onFailure={ this.handleLoginFailure }
          cookiePolicy={ 'single_host_origin' }
          responseType='code,token'
          // ref={this.inputFile}
        />
        
      }
      {/* { this.state.accessToken ? <h5>Your Access Token: <br/><br/> { this.state.accessToken }</h5> : null } */}
      {/* <img src={this.state.imageUrl} alt="" className="img-responsive"/> */}

    </div>
    )
  }
  async componentDidMount () {
    console.log("--- component mounted ---")
    console.log("inMemoryJWTManager.getToken(): "+ inMemoryJWTManager.getToken())
    if (!inMemoryJWTManager.getToken()){
      console.log("--- token does not exist, try refresh ---");
      this.tryRefreshFlow();
      
    }
/** commenting out below, as have decided to make access token long lived... it is not persistent, anyways */

    // //periodically keep refreshing AT, as it will expire shortly    
    // this.interval = setInterval(async () => {
    //   console.log("-- setInterval logic fired --");
    //   // var counter = 1;
    //   console.log("errorcounter: "+ this.state.errorcounter);
    //   if (inMemoryJWTManager.getToken() || this.state.errorcounter >=3){
    //     //stop trying
    //     console.log("--- stop trying to get a token ---");
    //     // inMemoryJWTManager.eraseToken();       
    //   } else {
    //     // call refresh token api
    //    this.tryRefreshFlow();
    //     // const token = await auth()
    //     // inMemoryToken = token;
    //   }    

    // }, 5000);

    // window.addEventListener('storage', this.syncLogout)
  }

  async tryRefreshFlow(counter){
    // call refresh token api
    console.log("-- AT NOT found --"  + inMemoryJWTManager.getToken());
    console.log("-- calling Refresh Tokens --");

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'grant_type':'refresh_token' },
      //for sending and receiving cookies.. sometime 'include' works as an alternate value
      credentials: 'include', 
      // body: JSON.stringify({
      //   "gTokenId": this.state.tokenId
      // } )
  };

    fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/tokens", requestOptions)
    .then(res => res.json())
    .then(
        (result) => {
            console.log("Platform Token: ");
            console.log(result);
            //  alert(result.errorType)
            if(!result.errorType){
              inMemoryJWTManager.setToken(result.body.token);
              console.log('token saved:'+ result.body.token);
              console.log('inMemoryJWTManager.getToken(): '+ inMemoryJWTManager.getToken());
              this.setState({
                  isLoaded: true,
                  isLogined: true,
                  imageUrl: result.body.picURL,
                  email: result.body.email
                  // doctors: result
              });
              // this.context.setState({
              //   loggedIn: this.state.isLogined
              this.context.setStatus(this.state.isLogined, this.state.email)
          
              // });
            }else{
              console.log('--- Refresh Flow failed ---')
              this.state.errorcounter++;
              console.log("errorcounter: "+ this.state.errorcounter);
            }
           
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // redirect to Login page
          console.log('--- Refresh Flow failed ---')  
          this.state.errorcounter++;
          console.log("errorcounter: "+ this.state.errorcounter);        
          // alert('Please Login')
          // this.inputFile.current.click();
            this.setState({
                isLoaded: true,
                error
            });
        }
    )

  }

}


GoogleBtn.contextType = AuthContext;
export default GoogleBtn;