import React, { Component } from 'react';
import {
    Container,
    Row,
    Col, 
Alert} from 'reactstrap';


import { Card, CardTitle, CardText, CardBody } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
// import "./style.css";
import LinkedInLogo from "./images/LinkedInLogo.png";
import StackOverflow from "./images/StackOverflow.png"
import Github from "./images/Github.png"
// import Vikram from "./images/vikram.png"
import { withRouter } from "react-router-dom";
import { AuthContext } from './AuthContext';
import PropTypes from 'prop-types';
import inMemoryJWTManager from './inMemoryJWTManager';



class FeedbackForm extends Component {

    // needed to use location
    static propTypes = {
        location: PropTypes.object.isRequired,
    
   }

    constructor(props) {
        super(props);

        this.submitFeedback = this.submitFeedback.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleFeedbackChange = this.handleFeedbackChange.bind(this);

        
        if(props.location.state==undefined){
            console.log("***** undefined");
            this.state = {
                title: "",
                feedback: "", 
                mode: ""
    
            };
            
        }else if(props.location.state["mode"]="edit"){
           
            this.state = {
                title: props.location.state["title"],
                feedback: props.location.state["feedback"],
                id: props.location.state["id"], 
                mode: "edit"        
    
            };
        } else if(props.location.state["mode"]="delete"){
           
            this.state = {
                title: props.location.state["title"],
                feedback: props.location.state["feedback"],
                id: props.location.state["id"], 
                mode: "delete"        
    
            };
        } else{
            this.state = {
                title: "",
                feedback: "", 
                mode: ""
    
            };
        }

        
    }

    handleTitleChange(event){
        this.setState({
            title: event.target.value

        })
       

    }

    handleFeedbackChange(event){
        this.setState({
            feedback: event.target.value

        })
        
    }
    
    // async componentDidMount () {
    //     console.log("--- Feedback Form::component mounted ---")
    //     console.log("inMemoryJWTManager.getToken(): "+ inMemoryJWTManager.getToken())

    //     console.log("this.context.status: " + this.context.status)
    // }
    
    
    submitFeedback(){
        console.log("--- title: " + this.state.title)

        console.log("--- feedback: " + this.state.feedback)
        console.log("--- email: " + this.context.email)

        console.log("--- mode: " + this.state.mode)

        if(this.state.mode == "edit"){

            let phToken = inMemoryJWTManager.getToken();
            let id = this.state.id;

            console.log("--- id: " + id)


        //call a PUT API to update feedback on backend
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': phToken
                
            },
            //for sending and receiving cookies.. sometime 'include' works as an alternate value            
            body: JSON.stringify({
              "title": this.state.title,
              "feedback": this.state.feedback
            } )
        };
    
          
          fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/feedbacks/"+id, requestOptions)
          .then(res => res.json())
          .then(
              (result) => {
                  console.log("Feedbacks result: ");
                  console.log(result);
                  this.props.history.push("/feedbacks", "true");
                
              },              
              (error) => {
                console.log("Feedbacks result with error: " + error);
              }
          )

        } else if (this.state.mode == "delete"){
            let phToken = inMemoryJWTManager.getToken();
            let id = this.state.id;

            console.log("--- id: " + id)


        //call a DELETE API to update feedback on backend
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': phToken
                
            },
                   
            body: JSON.stringify({
              "title": this.state.title,
              "feedback": this.state.feedback
            } )
        };
    
          
          fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/feedbacks/"+id, requestOptions)
          .then(res => res.json())
          .then(
              (result) => {
                  console.log("Feedbacks result: ");
                  console.log(result);
                  this.props.history.push("/feedbacks", "true");
                
              },              
              (error) => {
                console.log("Feedbacks result with error: " + error);
              }
          )


        } else{
            let phToken = inMemoryJWTManager.getToken();

        //call a POST API to save feedback on backend
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': phToken
                
            },
            //for sending and receiving cookies.. sometime 'include' works as an alternate value            
            body: JSON.stringify({
              "title": this.state.title,
              "feedback": this.state.feedback
            } )
        };
    
          
          fetch("https://8wfi29t2o1.execute-api.ap-south-1.amazonaws.com/dev/feedbacks", requestOptions)
          .then(res => res.json())
          .then(
              (result) => {
                  console.log("Feedbacks result: ");
                  console.log(result);
                  this.props.history.push("/feedbacks", "true");
                
              },              
              (error) => {
                console.log("Feedbacks result with error: " + error);
              }
          )

        }

        

        
       
    }

    

    render() {

        // return("hello world");

        

        const status = this.context.status;
        
        return (            
            
            <Container className="themed-container" fluid={true}>
<br></br>
            {
                !status &&  
                <Alert color="warning">
                        Please login, using the button available in the menu, so that you can leave feedback !!
                </Alert>
            }
            <div style={{border: '1px solid grey', padding: '12px'}}>
            <Form>
                <FormGroup>
                    <Label for="title">Title (max 50 words)</Label>
                    <Input type="text" name="title" id="title" placeholder=""  onChange={this.handleTitleChange} maxLength="50" value={this.state.title}/>
                </FormGroup>
                    <FormGroup>
                        <Label for="feedbackText">Please enter your feedback below!! (max 500 words)</Label>
                        <Input type="textarea" name="feedbackText" id="feedbackText"  onChange={this.handleFeedbackChange} maxLength="500" value={this.state.feedback}/>
                    </FormGroup>

                    { status && 
                    <Button onClick={()=>this.submitFeedback()}>Submit</Button>
                    }
            </Form>


            </div>          
         
            </Container>

            

        );
    }
}
FeedbackForm.contextType = AuthContext;
export default withRouter(FeedbackForm);